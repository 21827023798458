import React from "react";
import styled from "styled-components";

const Div = styled.div`
  width: 100%;
  height: 50px;
  background-color: black;
`;

export default function Footer() {
  return (
    <>
      <Div></Div>
    </>
  );
}
